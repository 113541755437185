<template>
  <div class="score-box">
    <span class="titulo"> {{ title }} </span>
    <img :src="imgArco" alt="" class="img-arco" />
    <img
      :src="imgPonteiro"
      alt=""
      class="img-ponteiro"
      :style="{ transform: 'rotate(' + calcularRotacao() + 'deg)' }"
    />
    <span class="texto" v-if="score != -9">
      {{ score }}
    </span>
    <span class="texto" v-if="score == -9"> - </span>
  </div>
</template>

<script>
export default {
  name: "ScoreSuperBox",
  data() {
    return {
      imgArco: "/img/superbox/Score-arco.png",
      imgPonteiro: "/img/superbox/Score-ponteiro.png",
    };
  },
  props: {
    score: Number,
    title: String,
  },

  methods: {
    calcularRotacao() {
      const score = this.score;
      let rotation = 0;
      if (score != "-") {
        if (score >= 0 && score <= 100) {
          rotation = score * 0.3;
        } else if (score > 100 && score <= 300) {
          rotation = 30 + (score - 100) * 0.15;
        } else if (score > 300 && score <= 500) {
          rotation = 60 + (score - 301) * 0.15;
        } else if (score > 500 && score <= 700) {
          rotation = 90 + (score - 501) * 0.15;
        } else if (score > 700 && score <= 900) {
          rotation = 120 + (score - 701) * 0.15;
        } else if (score > 900 && score <= 1000) {
          rotation = 150 + (score - 901) * 0.3;
        } else {
          rotation = 0;
        }
      } else {
        rotation = 0;
      }

      return rotation;
    },
  },
};
</script>

<style scoped lang="scss">
.score-box {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 200px;
  align-items: center;
  justify-content: center;
}
.img-ponteiro {
  width: 20%;
  position: relative;
  right: 15px;
  bottom: 15px;
  transform-origin: 45px 15px;
}

.titulo {
  font-weight: 500;
  font-size: 24px;
  color: #0070c0;
}

.texto {
  font-weight: 500;
  font-size: 20px;
  position: relative;
  bottom: 10px;
}
</style>
