<template>
  <div class="steps__container">
    {{ permissoes_func() }}
    <div class="item" :class="{ active: active(0), done: done(0) }">
      <a v-if="!permissoes[0]"> 0 </a>
      <router-link to="/consultas/modelos/0" v-else>0</router-link>
    </div>
    <div class="linha"></div>

    <div class="item" :class="{ active: active(1), done: done(1) }">
      <a v-if="!permissoes[1]"> 1 </a>
      <router-link to="/consultas/modelos/1" v-else>1</router-link>
    </div>
    <div class="linha"></div>

    <div class="item" :class="{ active: active(2), done: done(2) }">
      <a v-if="!permissoes[2]"> 2 </a>
      <router-link to="/consultas/modelos/2" v-else>2</router-link>
    </div>
    <div class="linha"></div>

    <div class="item" :class="{ active: active(3), done: done(3) }">
      <a v-if="!permissoes[3]"> 3 </a>
      <router-link to="/consultas/modelos/3" v-else>3</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Steps",
  props: {
    opcao_inicial: String,
    modeloSelecionado: Object,
  },
  data() {
    return {
      permissoes: [true, false, false, false],
    };
  },
  methods: {
    active(item) {
      if (this.$route.path.slice(-1) == item) {
        return true;
      }
      return false;
    },
    done(item) {
      if (parseInt(this.$route.path.slice(-1)) > item) {
        return true;
      }
      return false;
    },
    permissoes_func() {
      if (this.opcao_inicial) {
        this.permissoes[1] = true;
      } else {
        this.permissoes = [true, false, false, false];
      }

      if (this.modeloSelecionado != {}) {
        this.permissoes[2] = true;
      } else {
        this.permissoes[2] = false;
        this.permissoes[3] = false;
      }

      // if(this.contexto.length  != 0){
      //     this.permissoes[3] = true
      // }
      // else{
      //     this.permissoes[3] = false
      // }
    },
  },

  computed: {
    produtosSelecionados() {
      return this.produtos.filter((produto) => {
        return produto.Selecionado;
      });
    },
    // camposComValor(){
    //     return this.campos.filter(campo => {
    //         return campo.Valor
    //     })
    // },
  },
};
</script>
<style src="@/styles/components/Visuais/Steps.css" scoped></style>
