<template>
  <div class="steps__container">
    <div class="item-cancel">
      <router-link to="/consultas/box/3">
        <span class="material-icons-outlined"> arrow_back </span>
        Voltar para os resultados
      </router-link>
    </div>
    <div class="item" :class="{ active: active(4), done: done(4) }">
      <a v-if="!permissoes[0]"> 4 </a>
      <router-link to="/consultas/box/4" v-else>4</router-link>
    </div>
    <div class="linha"></div>

    <div class="item" :class="{ active: active(5), done: done(5) }">
      <a v-if="!permissoes[1]"> 5 </a>
      <router-link to="/consultas/box/5" v-else>5</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepsCiclica",
  props: {
    opcaoInicialSelecionada: String,
    produtos: Array,
    campos: Array,
    resultados: Object,
  },
  data() {
    return {
      permissoes: [true, false],
    };
  },
  methods: {
    active(item) {
      if (this.$route.path.slice(-1) == item) {
        return true;
      }
      return false;
    },
    done(item) {
      if (parseInt(this.$route.path.slice(-1)) > item) {
        return true;
      }
      return false;
    },
    permissoes_func() {
      if (this.produtosSelecionados.length > 0) {
        this.permissoes = [true, true];
      } else {
        this.permissoes = [true, false];
      }
    },
  },

  computed: {
    produtosSelecionados() {
      return this.produtos.filter((produto) => {
        return produto.Selecionado;
      });
    },
    camposComValor() {
      return this.campos.filter((campo) => {
        return campo.Valor;
      });
    },
  },
};
</script>
<style scoped>
.item,
.linha,
.item-cancel {
  margin-top: 50px;
}
</style>
<style src="@/styles/components/Visuais/Steps.css" scoped />
