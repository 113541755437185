<template>
  <div class="score-box">
    <span class="titulo"> {{ title }} </span>
    <img :src="imgArco" alt="" class="img-arco" />
    <img
      :src="imgPonteiro"
      alt=""
      class="img-ponteiro"
      :style="{ transform: 'rotate(' + calcularRotacao() + 'deg)' }"
    />
    <span class="texto">
      <span class="sub"> score </span>
      <span class="dest">
        {{ score }}
      </span>
      <span class="sub"> pontos </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "ScoreModelos",
  data() {
    return {
      imgArco: "/img/superbox/Score-arco.png",
      imgPonteiro: "/img/superbox/Score-ponteiro.png",
    };
  },
  props: {
    score: Number,
    title: String,
  },

  methods: {
    calcularRotacao() {
      const score = this.score;
      let rotation = 0;

      if (score >= 0 && score <= 100) {
        rotation = score * 0.3;
      } else if (score > 100 && score <= 300) {
        rotation = 30 + (score - 100) * 0.15;
      } else if (score > 300 && score <= 500) {
        rotation = 60 + (score - 301) * 0.15;
      } else if (score > 500 && score <= 700) {
        rotation = 90 + (score - 501) * 0.15;
      } else if (score > 700 && score <= 900) {
        rotation = 120 + (score - 701) * 0.15;
      } else if (score > 900 && score <= 1000) {
        rotation = 150 + (score - 901) * 0.3;
      } else {
        rotation = 0;
      }

      return rotation;
    },
  },
};
</script>

<style scoped lang="scss">
.score-box {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 200px;
  align-items: center;
  justify-content: flex-start;
}
.img-ponteiro {
  width: 20%;
  position: relative;
  right: 15px;
  bottom: 17px;
  transform-origin: 45px 15px;
}

.titulo {
  font-weight: 500;
  font-size: 24px;
  color: #0070c0;
  margin: 0 0 0px 0;
}

.texto {
  position: relative;
  bottom: 18px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #0070c0;

  .sub {
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
  }

  .dest {
    font-size: 20px;
    line-height: 18px;
    font-weight: 500;
  }
}
</style>
