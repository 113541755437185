<template>
  <div>
      <div class="wrapper-barra">
          <div class="barra">
            <div class="limitador" :style="{width: `${valor_percent}%`}">
            <div class="progresso" :class="verificaCorDaBarra()"></div>
            </div>
          </div>
          <p class="numero">{{valor}}/{{max}}</p>
          </div>
  </div>
</template>

<script>
export default {
    name: 'BarraProgresso',
    props: {
        valor: Number,
        valor_percent: Number,
        max: Number,
        indicador: String,
    },
    methods: {
        verificaCorDaBarra(){
          if(this.indicador == 'inicio_atividade'){
            if(this.valor_percent <= 30){
                return "redbar"
            }
            else if(this.valor_percent <= 60){
                return "yellowbar"
            }
            else if(this.valor_percent <= 100){
                return "bluebar"
            }
          }
          else if(this.indicador == 'capital_social'){
            if(this.valor_percent <= 20){
                return "redbar"
            }
            else if(this.valor_percent <= 60){
                return "yellowbar"
            }
            else if(this.valor_percent <= 100){
                return "bluebar"
            }
          }
            
        }
    },
    mounted() {
        // this.valor_percent = 30;
    }
}
</script>

<style>
.redbar{
    background: #DB7A6B;
}
.yellowbar{
    background: #FFB20F;
}
.bluebar{
    background:var(--accent);
}
.wrapper-barra{
  display:flex;
  align-items:center;
  width:100%;
  gap:10px;
  height:15px;
}
.numero{
  margin:0;
}
.barra{
  width:100%;
  background:#c4c4c4;
  border-radius:10px;
}
.progresso{
  border-radius:10px;
  animation: progress-animation 2s;
  height:9px;
}

.numero{
  align-self:center;
  opacity:0%;
  animation: opacity 1s linear forwards 1s;
}

@keyframes progress-animation{
  0% { width: 0%}
  100% { width: 100%}
}
@keyframes opacity{
  0% { opacity: 0;}
  100% { opacity: 100%;}
}
</style>