<template>
  <div class="flex-wrapper">
    <div class="single-chart">
      <svg viewBox="-1 -1 40 40" class="circular-chart color">
        <path
          class="circle-bg"
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          class="circle"
          :stroke-dasharray="circleDasharray"
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.35" class="percentage">{{ percentage }}%</text>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "CircleProgress",
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {},

  computed: {
    circleDasharray() {
      return [0, 100 - this.percentage, this.percentage];
      // return [10, 50, 30];
    },

    
  },
};
</script>

<style scoped>
.flex-wrapper {
  display: flex;
  flex-flow: row nowrap;
  width: 70vw;
  height: 70vh;
}

.single-chart {
  width: 33%;
  justify-content: space-around;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 90%;
  max-height: 90%;
}

.circle-bg {
  fill: none;
  stroke: #646464;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round; 
  stroke-miterlimit: 2;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.color .circle {
  stroke: #1A93E5;
}

.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}
</style>
