<template>
  <div class="heading">
    <div class="heading__left">
      <div class="heading__button" v-if="historico">
        <router-link class="back" to="/consultas/historico">
          <span class="material-icons-outlined icon"> arrow_back </span>
          <p class="return_label">Voltar para o histórico</p>
        </router-link>
      </div>
    </div>
    <div class="heading__right">
      <div class="heading__button" @click="gerapdf">
        <span class="material-icons">file_download</span>
        Download
      </div>
      <div class="heading__button" @click="$router.push('/consultas/box/0')">
        <span class="material-icons">refresh</span>
        Nova Consulta
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  name: "HeaderResultado",
  props: {
    codigoAgrupador: String,
    historico: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async gerapdf() {
      this.analytics(
        "Impressão PDF",
        "Utilitários",
        "O usuário gerou um pdf dos resultados obtidos"
      );

      var worker = html2pdf();
      var opt = {
        margin: 0.3,
        filename: `${this.codigoAgrupador}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 1.6,
          allowTaint: true,
          useCors: true,
        },
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        pagebreak: { mode: "avoid-all" },
      };
      // var items = document.querySelector(".esqueleto");

      var cards = document.getElementsByClassName("card");

      var elementToPrint = document.createElement("div");
      elementToPrint.classList.add("html2pdf__page-break");

      var pulaLinha = document.createElement("br");
      cards.forEach((box) => {
        const boxClone = box.cloneNode(true);
        boxClone.classList.add("html2pdf__page-break");
        elementToPrint.appendChild(boxClone.cloneNode(true));
        elementToPrint.appendChild(pulaLinha.cloneNode(true));
      });
      await worker.set(opt).from(elementToPrint).toPdf().save();
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 2;
  position: relative;

  &__left {
    .back {
      display: flex;
      color: $white;
      align-items: flex-start;
      gap: 0.5rem;

      .return_label {
        color: $white;
      }

      .icon {
        color: $white;
        font-size: 1.2rem;
        padding: 0;
        margin: 0;
      }
    }
  }

  &__right {
    display: flex;
    gap: 0.5rem;
  }

  &__button {
    display: flex;
    padding: 10px;
    border-radius: 10px;
    color: $white;
    background: $bg-sidebar;
    transition: 0.3s ease all;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 5px 10px 3px $mark;
    }
  }

  .return_label {
    display: none;
    @include mediaQuery(medium) {
      display: block;
    }
  }
}
</style>
