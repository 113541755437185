<template>
    <div class="gauge-box">
        <div class="title">
            {{titulo}}
        </div>
        <div class="limit">
            <div class="gauge">
                 <vue-svg-gauge
                    :start-angle="-110"
                    :end-angle="110"
                    :value="Number(valor)"
                    :separator-step="50"
                    :min="0"
                    :max="1000"
                    :gauge-color="[{ offset: 0, color: '#f44336' },{ offset: 40, color: '#fec60a' }, { offset: 80, color: '#8bc34a' }]"
                    :scale-interval="50"
                  />
            </div>
            <div class="valor">
                {{valor}}
            </div>  
        </div>
        <div class="classificacoes">
            <div class="classificacao" v-if="classificacao">
                Classificação: {{classificacao}}
            </div>
            <div class="rating">
                Rating: {{rating}}
            </div>
        </div>
    </div>


    
</template>

<script>
export default {
    name: 'Gauge',
    props:{
        titulo: String,
        valor: Number,
        classificacao: String,
        rating: String,
    }
}
</script>

<style>
.gauge-box{
    display:flex;
    flex-direction: column;
    gap:15px;
    width:100%;
    justify-content: center;
    align-items: center;
}
.title{
    margin-bottom:15px;
    text-align:center;
    font-size: 1.5rem;
}
.limit{
    height: 120px;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}
.classificacoes{
    text-align:center;
}
</style>