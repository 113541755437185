<template>
  <md-card class="fieldBar">
    <b>{{ name }}</b>
    <span class="infoValidacao"
      ><span>Linhas validas: {{ item.validos }}</span
      ><span>Linhas Invalidas: {{ item.invalidos }}</span>
    </span>

    <b-progress class="validacaoBar" show-progress>
      <b-progress-bar
        class="progress"
        :id="'valido-' + name"
        :value="(item.validos / (item.validos + item.invalidos)) * 100"
        variant="success"
      >
        {{
          ((item.validos / (item.validos + item.invalidos)) * 100).toFixed(2) +
          "%"
        }}
      </b-progress-bar>
      <b-tooltip
        :target="'valido-' + name"
        triggers="hover"
        placement="bottom"
        variant="secondary"
        class="tooltipResults"
      >
        Percentual de registros válidos
      </b-tooltip>
      <b-progress-bar
        class="progress"
        :id="'invalido-' + name"
        :value="(item.invalidos / (item.validos + item.invalidos)) * 100"
        variant="danger"
      >
        {{
          ((item.invalidos / (item.validos + item.invalidos)) * 100).toFixed(
            2
          ) + "%"
        }}
      </b-progress-bar>
      <b-tooltip
        :target="'invalido-' + name"
        triggers="hover"
        placement="bottom"
        variant="secondary"
        class="tooltipResults"
      >
        <span v-if="name == 'cep'">
          Os CEP que estiverem inválidos serão inferidos com base no CPF/CNPJ
          informados
        </span>
        <span v-else>
          Certifique-se que o mapeamento de colunas e formatos para este dado
          estejam corretamente selecionados
        </span>
      </b-tooltip>
    </b-progress>
  </md-card>
</template>

<script>
export default {
  name: "BarChart",
  components: {},
  props: {
    resultadoValidacao: Object,
    item: Object,
    name: String,
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
.fieldBar {
  width: 95%;
  min-height: 70px;
  margin: 10px 10px 10px 10px !important;
  padding: 5px 0 5px 5px;
  display: flex;
  justify-content: center;
}
.validacaoBar {
  width: 95%;
  height: 30px;
  margin: 5px;
  border-radius: 2px;
}
.infoValidacao {
  width: 95%;
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 5px;
}
.progress {
  border-radius: 2px;
  height: 30px;
}
.progress:hover {
  cursor: pointer;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}
</style>