import { render, staticRenderFns } from "./ConfirmaSelecao.vue?vue&type=template&id=32e2251b&scoped=true&"
import script from "./ConfirmaSelecao.vue?vue&type=script&lang=js&"
export * from "./ConfirmaSelecao.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmaSelecao.vue?vue&type=style&index=0&id=32e2251b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32e2251b",
  null
  
)

export default component.exports