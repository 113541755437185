<template>
  <div class="pie">
    <canvas :id="`myChart${id}`" width="400" height="400"></canvas>
  </div>
</template>

<script>
import { utilitarios } from "@/helpers/utilitarios";

export default {
  name: "Pie",
  props: {
    data: Array,
    label: String,
    labels: Array,
    position: String,
  },
  data() {
    return {
      id: utilitarios.makeId(),
    };
  },
  mounted() {
    const ctx = document.getElementById(`myChart${this.id}`).getContext("2d");
    const myChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: this.labels,
        datasets: [
          {
            label: this.label,
            data: this.data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.4)",
              "rgba(54, 162, 235, 0.4)",
              "rgba(255, 206, 86, 0.4)",
              "rgba(75, 192, 192, 0.4)",
              "rgba(153, 102, 255, 0.4)",
              "rgba(255, 159, 64, 0.4)",
            ],
            hoverBackgroundColor: [
              "rgba(255, 99, 132, 0.8)",
              "rgba(54, 162, 235, 0.8)",
              "rgba(255, 206, 86, 0.8)",
              "rgba(75, 192, 192, 0.8)",
              "rgba(153, 102, 255, 0.8)",
              "rgba(255, 159, 64, 0.8)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 0,
          },
        ],
      },
      options: {
        legend: {
          position: "top",
          labels: {
            boxWidth: 10,
            boxHeight: 10,
            textAlign: "center",
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  },

  
};
</script>

<style lang="scss" scoped>
.pie {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 250px;
  max-height: 250px;
}
</style>