<template>
  <div class="labelField">
    <div class="nomeBacktest">
      <span class="material-symbols-outlined fileUp">{{ iconLabel }}</span>
      <strong class="label">{{ label }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: "TituloBox",
  components: {},
  props: {
    label: String,
    iconLabel: String,
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.labelField {
  width: 100%;
  cursor: default;
}
.nomeBacktest {
  font-weight: 700;
  color: var(--accent-60);
  font-size: 1rem;
  border-bottom: 2px solid var(--accent-50);
  width: 95%;
  margin: 10px 5px 10px 5px;
  min-height: 1.8rem;
  display: flex;
  word-break: break-word;
  align-items: center;

  @include mediaQueryMax(medium) {
    line-height: 2rem;
  }
  @include mediaQueryMax(small) {
    line-height: 1.5rem;
    max-width: 100%;
  }
}

.fileUp {
  vertical-align: middle;
  color: #626568;
  margin: 0 5px 1px 0;
  height: 2rem;
  display: flex;
  align-items: center;
}

.label {
  color: rgb(33, 150, 243, 60%);
  min-height: 1rem;

  @include mediaQueryMax(small) {
    width: 80%;
  }
}
</style>