<template>
  <div
    class="dropdown"
    :class="{ 'dropdown--open': openChild }"
    @click="openChild = !openChild"
  >
    <div class="header">
      <div class="header__esq">
        <span class="versao">Versão {{ versao }}</span>
      </div>
      <div class="header__dir">
        <span class="material-symbols-rounded">expand_more</span>
      </div>
    </div>
    <div class="corpo" :class="{ 'corpo--aberto': openChild }">
      <slot />
    </div>
  </div>
</template>   

<script>
export default {
  name: "Dropdown",
  props: {
    versao: String,
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openChild: false,
    };
  },
  mounted() {
    if (this.open) {
      this.openChild = true;
    }
  },
};
</script>


<style lang="scss" scoped>
.dropdown {
  padding: 0.5rem;
  // color: $bg-card;
  // background:$bg-sidebar;

  color: $bg-sidebar;
  background: $white;

  border-radius: 10px;
  cursor: pointer;
  // animation: sobe 1s forwards ;
  max-width: 100%;
  min-width: 100%;
  border: 1px solid transparent;

  &--open {
    border: 1px solid $accent;
  }
}

@keyframes sobe {
  0% {
    transform: translateY(200px);
  }
  100% {
    transform: translateY(0px);
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__esq {
    display: flex;
    flex-direction: column;
    align-items: center;

    .versao {
      color: $bg-sidebar;
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
}

.corpo {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 0;
  overflow: hidden;
  &--aberto {
    height: fit-content;
    padding-top: 1.5rem;
    padding-left: 0.5rem;
  }
}
.filhos {
  border-bottom: 2px solid transparent;
  transition: 0.3s ease-in all;
  cursor: pointer;
  width: fit-content;

  .nome {
    color: $accent;
    font-weight: 600;
  }

  .nomeAmigavel {
    .icon {
      color: $accent;
      font-size: 1rem;
    }
  }

  @include mediaQuery(medium) {
    &:hover {
      border-bottom: 2px solid $accent;
    }
  }
}
.header__esq {
  width: 75px;
}
</style>