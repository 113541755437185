<template>
  <div class="containerIconDouble">
    <div class="boxIcon">
      <span class="material-icons biggerIcon" :style="{ color: colorBigger }">
        {{ biggerIcon }}
      </span>
    </div>

    <div class="boxIcon stack-top-icon">
      <span class="material-icons lowerIcon" :style="{ color: colorLower }">
        {{ lowerIcon }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "DoubleIcon",
  props: ["lowerIcon", "biggerIcon", "colorBigger", "colorLower"],
};
</script>

<style lang="scss" scoped>
.containerIconDouble {
  width: 1rem;
  height: 2rem;
  position: relative;
  // background-color: red;
  @include mediaQuery(large) {
    margin-right: -0.2rem;
    width: 5rem;
    height: 4.1rem;
  }

  @include mediaQueryMax(large) {
    width: 3rem;
    height: 3rem;
    margin-right: -0.4rem;
  }

  @include mediaQueryMax(medium) {
    width: 2rem;
    height: 2.7rem;
  }

  @include mediaQueryMax(small) {
    width: 0.5rem;
    height: 2rem;
    margin-right: -1rem;
  }
}

.boxIcon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  /* for demo purpose  */
  .biggerIcon {
    font-size: 4rem;

    @include mediaQuery(large) {
      font-size: 4rem;
    }

    @include mediaQueryMax(large) {
      font-size: 3vw;
    }

    @include mediaQueryMax(medium) {
      font-size: 2.5rem;
    }

    @include mediaQueryMax(small) {
      font-size: 1.75rem;
    }
  }

  .lowerIcon {
    font-size: 2rem;
    background-color: var(--color);

    @include mediaQuery(large) {
      font-size: 1.5rem;
    }

    @include mediaQueryMax(large) {
      font-size: 1.5vw;
    }

    @include mediaQueryMax(medium) {
      font-size: 1rem;
    }

    @include mediaQueryMax(small) {
      font-size: 1rem;
    }
  }
}

.stack-top-icon {
  z-index: 9;
  margin-left: 2.9rem;
  margin-top: 2rem;

  @include mediaQuery(large) {
    margin-left: 2.9rem;
    margin-top: 2.8rem;
  }

  @include mediaQueryMax(large) {
    margin-left: 2.2em;
    margin-top: 1.9rem;
  }

  @include mediaQueryMax(medium) {
    margin-left: 2rem;
    margin-top: 1.7rem;
  }

  @include mediaQueryMax(small) {
    margin-left: 1.3rem;
    margin-top: 1rem;
  }
}
</style>