<template>
  <div class="steps__container">
    {{ permissoes_func() }}
    <div class="item" :class="{ active: active(0), done: done(0) }">
      <a v-if="!permissoes[0]"> 0 </a>
      <router-link :to="'/integracoes_skynet/' + id + '/0'" v-else
        >0</router-link
      >
    </div>
    <div class="linha"></div>

    <div class="item" :class="{ active: active(1), done: done(1) }">
      <a v-if="!permissoes[1]"> 1 </a>
      <router-link :to="'/integracoes_skynet/' + id + '/1'" v-else
        >1</router-link
      >
    </div>
    <div class="linha"></div>

    <div class="item" :class="{ active: active(2), done: done(2) }">
      <a v-if="!permissoes[2]"> 2 </a>
      <router-link :to="'/integracoes_skynet/' + id + '/2'" v-else
        >2</router-link
      >
    </div>
    <div class="linha"></div>
    <div class="item" :class="{ active: active(3), done: done(3) }">
      <a v-if="!permissoes[3]"> 3 </a>
      <router-link :to="'/integracoes_skynet/' + id + '/3'" v-else
        >3</router-link
      >
    </div>
    <div class="linha"></div>
    <div class="item" :class="{ active: active(4), done: done(4) }">
      <a v-if="!permissoes[4]"> 4 </a>
      <router-link :to="'/integracoes_skynet/' + id + '/4'" v-else
        >4</router-link
      >
    </div>
    <div class="linha"></div>
    <div class="item" :class="{ active: active(5), done: done(5) }">
      <a v-if="!permissoes[5]"> 5 </a>
      <router-link :to="'/integracoes_skynet/' + id + '/5'" v-else
        >5</router-link
      >
    </div>
    <div class="linha"></div>
    <div class="item" :class="{ active: active(6), done: done(6) }">
      <a v-if="!permissoes[6]"> 6 </a>
      <router-link :to="'/integracoes_skynet/' + id + '/6'" v-else
        >6</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "StepsSkynet",
  props: {},

  data() {
    return {
      permissoes: [true, false, false, false, false, false, false],
      id: this.$route.params.id,
    };
  },
  methods: {
    active(item) {
      if (this.$route.path.slice(-1) == item) {
        this.permissoes[item] = true;
        return true;
      }
      if (this.permissoes[6] == true) {
        this.permissoes = [true, false, false, false, false, false, false];
      }
      return false;
    },
    done(item) {
      if (parseInt(this.$route.path.slice(-1)) > item) {
        return true;
      }
      return false;
    },
    permissoes_func() {
      // if (this.opcaoPessoaSelecionada) {
      // this.permissoes[0] = true;
      // this.permissoes[1] = true;
      // } else {
      //   this.permissoes = [true, false, false];
      // }
      // if (this.opcaoAbordagemSelecionada) {
      //   this.permissoes[2] = true;
      // } else {
      //   this.permissoes[2] = false;
      // }
    },
  },
};
</script>

<style>
.steps__container {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.item a {
  color: black;
  background: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1.6px solid black;
  background: var(--bg-card);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease all;
}

.linha {
  width: 35px;
  height: 1px;

  align-self: center;
  background: black;
}

.active a {
  background: var(--mark-30);
  color: white !important;
}

.done a {
  background: var(--mark);
  color: white !important;
}

.item a:hover {
  color: none !important;
}

.item-cancel {
  position: absolute;
  top: 40px;
  left: 60px;

  height: 50px;
  width: 250px;
}

.item-cancel a {
  display: flex;
  gap: 10px;
  align-items: center;
}

@media (min-width: 1024px) {
  .item a {
    width: 60px;
    height: 60px;
  }

  .linha {
    width: 70px;
  }
}
</style>