<template>
  <div>
    <transition name="fade" appear>
      <div class="modal-overlay" @click="avisaQueFechou" />
    </transition>
    <div class="modal">
      <div class="texto">
        Alguns produtos selecionados não estão visíveis devido a um filtro
        ativo.
        <br />Selecione quais produtos serão consultados:
        <div class="obs">
          * Produtos marcados estão omitidos pelo filtro atual
        </div>
      </div>
      <div
        class="lista"
        :class="{ filtrado: !eFiltrado(produto) }"
        v-for="produto in produtos_"
        :key="produto.Box"
      >
        <div class="texto-esq">
          <checkCustom
            :checked="true"
            :produto="produto"
            @selected="selecionaProduto(produto)"
          />
          {{ produto.Nome }}
        </div>
        <div class="icon-dir" v-if="!eFiltrado(produto)">
          <span class="material-icons-outlined">visibility_off</span>
        </div>
      </div>
      <div class="buttons">
        <button class="btn cancel" @click="emiteDecisao(2)">
          Voltar para seleção
        </button>
        <button
          class="btn"
          :disabled="nenhumSelecionado"
          :class="{
            '--disabled': nenhumSelecionado,
            confirmar: !nenhumSelecionado,
          }"
          @click="emiteDecisao(1)"
        >
          Confirmar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmaSelecao",
  components: {},
  props: {
    produtos: Array,
    search: String,
    texto: Boolean,
    values: Array,
  },
  data() {
    return {
      produtos_: this.produtos.map((produto) => ({ ...produto })),
      nenhumSelecionado: false,
    };
  },
  components: {
    checkCustom: () => import("@/components/Visuais/checkCustom.vue"),
  },

  methods: {
    selecionaProduto(produto) {
      if (this.produtosSelecionados == 0) {
        this.nenhumSelecionado = true;
      } else {
        this.nenhumSelecionado = false;
      }
    },
    selecionadoNaOriginal(produto) {
      this.produtos.forEach((produtoOriginal) => {
        if (produto.Box == produtoOriginal.Box) {
          if (produtoOriginal.Selecionado) {
            return true;
          } else {
            return false;
          }
        }
      });
    },
    avisaQueFechou() {
      this.$emit("fechaModal");
    },
    // selectCard(){
    //   produto.Selecionado = !produto.Selecionado
    // },
    emiteDecisao(op) {
      switch (op) {
        case 1:
          this.$emit("substituiProdutos", this.produtos_);
          this.avisaQueFechou();
          break;
        case 2:
          this.avisaQueFechou();
          break;
      }
    },
    eFiltrado(produto) {
      if (this.texto) {
        if (
          produto.Nome.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          produto.Descricao.toLowerCase().indexOf(this.search.toLowerCase()) >
            -1
        ) {
          return true;
        }
      } else {
        return this.verificaTags(produto, this.values);
      }
    },
    verificaTags(produto, values) {
      if (values.length > 0) {
        let x = false;
        values.forEach((value) => {
          produto.Tags.forEach((tag) => {
            if (tag === value) {
              x = true;
            }
          });
        });
        return x;
      } else {
        return true;
      }
    },
  },
  computed: {
    produtosSelecionados() {
      let x = this.produtos_.filter((produto) => {
        return produto.Selecionado;
      });
      return x.length;
    },
  },
  mounted() {
    scrollTo(0, 0);
  },
};
</script>
<style scoped>
.modal-overlay {
  position: absolute;
  top: -200px;
  left: -50px;
  right: -200px;
  z-index: 98;
  background-color: rgba(0, 0, 0, 0.3);
}
.modal {
  position: fixed;
  top: 10%;
  left: calc(50%);
  transform: translate(-50%, -50%);
  z-index: 99;
  max-height: 80%;
  width: 95%;
  padding: 10px;
  background-color: #fff;
  border-radius: 16px;

  overflow: auto;

  display: flex;
  flex-direction: column;
  gap: 5px;
}
.filtrado {
  background: var(--accent-30);
}
.texto-esq {
  padding-left: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.texto {
  padding-left: 20px;
  font-weight: 400;
  font-size: 1rem;
  text-align: left;
  margin-bottom: 20px;
}
.obs {
  font-size: 0.8rem;
  font-weight: 100;
}
.lista {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  justify-content: space-between;
}

.icon-dir {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.lista__selecionados {
  display: flex;
  gap: 10px;
  align-items: center;
}

.buttons {
  margin-top: 20px;
  font-size: 0.9rem;
  gap: 5px;
  display: flex;
}
.btn {
  color: white;
  font-weight: 500;
  border-radius: 15px;
  width: 150px;
  border: none;
}

button.--disabled {
  text-align: center;
  background: var(--mark-30);
  cursor: not-allowed;
  transition: 0.4s ease all;
}

.disabled:hover {
  box-shadow: none;
}
.confirmar {
  text-align: center;
  background: var(--mark);
  cursor: pointer;
  transition: 0.4s ease all;
}

.cancel {
  background: var(--bg-sidebar-70);
  cursor: pointer;
}

.todos:hover {
  box-shadow: 0px 5px 10px 1px var(--positive-green);
}
.confirmar:hover {
  box-shadow: 0px 5px 10px 1px var(--mark);
}
.cancel:hover {
  box-shadow: 0px 5px 10px 1px var(--text-gray);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(-50%) translateX(100vw);
}

@media (min-width: 1024px) {
  .modal-overlay {
    overflow: hidden;
    left: -10%;
    right: -185px;
    left: -200px;
    bottom: -30%;
  }
  .modal {
    top: 30%;
    width: 60%;
    padding: 40px;
  }
  .lista {
    padding-left: 20px;
  }
  .texto {
    font-size: 1rem;
  }
  .buttons {
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }
  .btn {
    padding: 10px;
  }
}

@media (min-width: 1700px) {
  .modal-overlay {
    bottom: -300px;
  }
}
</style>
